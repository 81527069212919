import React, { FC, useMemo } from 'react'
import { ForeCast } from '../../../stores/ADMarketTypes.types'
import s from './mediaplan.module.scss'
import IntlFormatter from '../../../utils/intl-money-formatter'
import time from './images/time.svg'
interface IForeCastTable {
	forecast: ForeCast
}

const ForeCastTable: FC<IForeCastTable> = ({ forecast }) => {
	const bookingAD = useMemo<{
		[k in 'indoor' | 'outdoor']?: typeof forecast.booking
	}>(() => {
		let res: { [k in 'indoor' | 'outdoor']?: typeof forecast.booking } = {}
		if (forecast.booking.length === 0) return res
		forecast.booking.reduce((acc, b) => {
			if (
				b.placement.door_type === 'indoor' &&
				forecast.indoor_adv_is_selected
			) {
				if (acc.indoor) {
					acc.indoor = [...acc.indoor, b]
				} else {
					acc.indoor = [b]
				}
			}
			if (
				b.placement.door_type === 'outdoor' &&
				forecast.outdoor_adv_is_selected
			) {
				if (acc.outdoor) {
					acc.outdoor = [...acc.outdoor, b]
				} else {
					acc.outdoor = [b]
				}
			}
			return acc
		}, res)
		return res
	}, [forecast])

	const internetAD = useMemo<{
		[k in 'vk' | 'yandex']?: typeof forecast.yandex
	}>(() => {
		const res: { [k in 'vk' | 'yandex']?: typeof forecast.yandex } = {}
		if (forecast.vk.is_selected) res.vk = forecast.vk
		if (forecast.yandex.is_selected) res.yandex = forecast.yandex
		return res
	}, [forecast])

	const tvAD = useMemo<{
		[k in 'teletarget' | 'adstream']?:
			| typeof forecast.adstream
			| typeof forecast.teletarget
	}>(() => {
		const res: {
			[k in 'teletarget' | 'adstream']?:
				| typeof forecast.adstream
				| typeof forecast.teletarget
		} = {}
		if (forecast.adstream.is_selected) res.adstream = forecast.adstream
		if (forecast.teletarget.is_selected)
			res.teletarget = forecast.teletarget
		return res
	}, [forecast])

	const publicTransportAd = useMemo<{
		[k in 'public_transport' | 'metro']?:
			| typeof forecast.public_transport
			| typeof forecast.metro
	}>(() => {
		const res: {
			[k in 'public_transport' | 'metro']?:
				| typeof forecast.public_transport
				| typeof forecast.metro
		} = {}
		if (forecast.public_transport.is_selected) {
			res.public_transport = forecast.public_transport
		}

		if (forecast.metro.is_selected) {
			res.metro = forecast.metro
		}
		return res
	}, [forecast])

	const totals = useMemo<{
		shows: number
		amount: number
		clicks: number
	}>(() => {
		const res = { shows: 0, amount: 0, clicks: 0 }
		for (const [, value] of Object.entries(internetAD)) {
			res.clicks += value.forecast_views_count
				? value.forecast_views_count
				: 0
			res.amount += value.amount ? value.amount : 0
		}
		for (const [, value] of Object.entries(tvAD)) {
			res.shows += value.forecast_views_count
				? value.forecast_views_count
				: 0
			res.amount += value.amount ? parseFloat(value.amount) : 0
		}
		for (const [, bookings] of Object.entries(bookingAD)) {
			for (let value of bookings) {
				res.shows += value.forecast_views_count
					? value.forecast_views_count
					: 0
				res.amount += value.booking_budget ? value.booking_budget : 0
			}
		}
		if (
			forecast.district_adv_is_selected &&
			!!forecast.district_booking.length
		) {
			for (let value of forecast.district_booking) {
				res.shows += value.forecast_views_count
					? value.forecast_views_count
					: 0
				res.amount += value.amount ? +value.amount : 0
			}
		}
		if (forecast.public_transport?.is_selected) {
			res.amount += parseFloat(forecast.public_transport.amount || '0')
			res.shows += forecast.public_transport.forecast_views_count || 0
		}

		if (forecast.metro?.is_selected) {
			res.amount += parseFloat(forecast.metro.amount || '0')
			res.shows += forecast.metro.forecast_views_count || 0
		}
		return res
	}, [bookingAD, internetAD, tvAD, forecast])

	return (
		<table className={s.forecastTable}>
			<tbody>
				<tr>
					<th
						rowSpan={2}
						style={{ width: '200px', borderTopLeftRadius: '8px' }}
					>
						Формат рекламы
					</th>
					<th rowSpan={2} style={{ width: '372px' }}>
						Место размещения
					</th>
					<th
						colSpan={4}
						style={{
							borderTopRightRadius: '8px',
							textAlign: 'center',
						}}
					>
						Прогнозные показатели
					</th>
				</tr>
				<tr>
					<th style={{ width: '180px', textAlign: 'right' }}>
						Показы
					</th>
					<th style={{ width: '180px', textAlign: 'right' }}>
						Клики
					</th>
					<th style={{ width: '148px', textAlign: 'right' }}>
						Стоимость
					</th>
					<th style={{ width: '148px', textAlign: 'right' }}>
						Стоимость c НДС
					</th>
				</tr>
				{forecast.district_adv_is_selected &&
					!!forecast.district_booking.length &&
					forecast.district_booking.map((el, index) => (
						<tr key={el.id}>
							{index === 0 && (
								<td
									rowSpan={forecast.district_booking.length}
									style={{ verticalAlign: 'baseline' }}
								>
									Наружная реклама - Максимальный охват
								</td>
							)}
							<td>{el?.city_name}</td>
							<td className={s.tdNumbers}>
								{`~ ${el.forecast_views_count}`}
							</td>
							<td className={s.tdNumbers}>-</td>
							<td className={s.tdNumbers}>
								{IntlFormatter.format(el.amount, false)}
							</td>
							<td className={s.tdNumbers}>
								{IntlFormatter.format(
									parseFloat(el.amount) * 1.2,
									false
								)}
							</td>
						</tr>
					))}

				{Object.keys(bookingAD).length !== 0 &&
					Object.entries(bookingAD).map(([key, bookings]) =>
						bookings.map((b, index) => (
							<tr key={b.id}>
								{index === 0 && (
									<td
										rowSpan={bookings.length}
										style={{ verticalAlign: 'baseline' }}
									>
										{key === 'outdoor' &&
											'Наружная реклама - Гарантированные показы'}
										{key === 'indoor' &&
											'Внутренняя реклама'}
									</td>
								)}
								<td>
									{b.placement.placement_type.name} <br />
									{b.placement.name}
								</td>

								<td className={s.tdNumbers}>
									{`~ ${b.forecast_views_count}`}
								</td>
								<td className={s.tdNumbers}>-</td>
								<td className={s.tdNumbers}>
									{IntlFormatter.format(
										b.booking_budget,
										false
									)}
								</td>
								<td className={s.tdNumbers}>
									{IntlFormatter.format(
										(b.booking_budget || 0) * 1.2,
										false
									)}
								</td>
							</tr>
						))
					)}
				{Object.keys(internetAD).length !== 0 &&
					Object.entries(internetAD).map(([key, internet], index) => (
						<tr key={internet.id}>
							{index === 0 && (
								<td
									rowSpan={Object.keys(internetAD).length}
									style={{ verticalAlign: 'baseline' }}
								>
									Интернет реклама
								</td>
							)}
							<td>
								{key === 'yandex' && 'Яндекс'}
								{key === 'vk' && 'VK Реклама'}
							</td>
							<td className={s.tdNumbers}>-</td>
							<td className={s.tdNumbers}>
								{internet.forecast_views_count}
							</td>
							<td className={s.tdNumbers}>
								{IntlFormatter.format(internet.amount, false)}
							</td>
							<td className={s.tdNumbers}>
								{IntlFormatter.format(
									(internet.amount || 0) * 1.2,
									false
								)}
							</td>
						</tr>
					))}

				{Object.keys(publicTransportAd).length !== 0 &&
					Object.entries(publicTransportAd).map(
						([key, pt], index) => (
							<tr key={pt.id}>
								{index === 0 && (
									<td
										rowSpan={
											Object.keys(publicTransportAd)
												.length
										}
										style={{ verticalAlign: 'baseline' }}
									>
										Общественный транспорт
									</td>
								)}
								<td>
									{key === 'metro' &&
										'Цифровые экраны на МЦД'}
									{key === 'public_transport' &&
										'Цифровые экраны на МЦК'}
								</td>
								<td className={s.tdNumbers}>
									{`~ ${pt.forecast_views_count}`}
								</td>
								<td className={s.tdNumbers}>-</td>
								<td className={s.tdNumbers}>
									{IntlFormatter.format(pt.amount, false)}
								</td>
								<td className={s.tdNumbers}>
									{IntlFormatter.format(
										parseFloat(pt.amount || '0') * 1.2,
										false
									)}
								</td>
							</tr>
						)
					)}
				{Object.keys(tvAD).length !== 0 &&
					Object.entries(tvAD).map(([key, tv], index) => (
						<tr key={tv.id}>
							{index === 0 && (
								<td
									rowSpan={Object.keys(tvAD).length}
									style={{ verticalAlign: 'baseline' }}
								>
									ТВ-реклама{' '}
								</td>
							)}
							<td>
								{key === 'teletarget' && 'Teletarget'}
								{key === 'adstream' && 'Adstream '}
							</td>
							<td className={s.tdNumbers}>
								{`~ ${tv.forecast_views_count}`}
							</td>

							<td className={s.tdNumbers}>-</td>
							<td className={s.tdNumbers}>
								{IntlFormatter.format(tv.amount, false)}
							</td>
							<td className={s.tdNumbers}>
								{IntlFormatter.format(
									parseFloat(tv.amount || '0') * 1.2,
									false
								)}
							</td>
						</tr>
					))}
				<tr style={{ textAlign: 'right' }}>
					<th
						colSpan={2}
						style={{
							borderBottomLeftRadius: '8px',
						}}
					>
						Итого:
					</th>
					<th>{`~ ${totals.shows}`}</th>
					<th>{`~ ${totals.clicks}`}</th>
					<th>{IntlFormatter.format(totals.amount, true)}</th>
					<th style={{ borderBottomRightRadius: '8px' }}>
						{IntlFormatter.format(totals.amount * 1.2, true)}
					</th>
				</tr>
			</tbody>
		</table>
	)
}

export default ForeCastTable
